import React from "react";
import Layout from "components/layout";
import JobList from "components/job_list";
import EntryButton from "components/entry_button";
import Style from "styles/recruit_item.module.scss";
import SEO from "components/seo";

const Service = () => {
  return (
    <Layout>
      <SEO title="Backendengineer|Recruit" path="recruit/backendengineer" />
      <section className={Style.recruitItemHeading}>
        <div className={Style.recruitItemHeading__inner}>
          <h2 className={Style.recruitItemHeading__subtitle}>RECRUIT</h2>
          <h1 className={Style.recruitItemHeading__title}>BACKEND ENGINEER</h1>
        </div>
      </section>
      <section className={Style.recruitItemOverview}>
        <div className={Style.recruitItemOverview__inner}>
          <table className={Style.recruitItemOverviewTable}>
            <tbody className={Style.recruitItemOverviewTable__tbody}>
              <tr className={Style.recruitItemOverviewTable__item}>
                <th className={Style.recruitItemOverviewTable__head}>職種</th>
                <td className={Style.recruitItemOverviewTable__content}>バックエンドエンジニア</td>
              </tr>
              <tr className={Style.recruitItemOverviewTable__item}>
                <th className={Style.recruitItemOverviewTable__head}>業務内容</th>
                <td className={Style.recruitItemOverviewTable__content}>
                  ECサイト、コンシューマー向けサービス、大規模ポータルサイトなどの開発・ 改修・運用
                  <br />
                  社内ツールの開発・運用
                  <br />※ ご経験を考慮しお仕事をお任せします。
                </td>
              </tr>
              <tr className={Style.recruitItemOverviewTable__item}>
                <th className={Style.recruitItemOverviewTable__head}>必須スキル・経験</th>
                <td className={Style.recruitItemOverviewTable__content}>
                  プログラミング言語(Java, PHP, C#)等を使用した開発経験が3年以上
                  <br />
                  UNIXまたはLinuxのコマンド操作が可能な方
                </td>
              </tr>
              <tr className={Style.recruitItemOverviewTable__item}>
                <th className={Style.recruitItemOverviewTable__head}>歓迎するスキル・経験</th>
                <td className={Style.recruitItemOverviewTable__content}>
                  AWS、Azure、GCP等のクラウドサービスを使用した開発経験
                  <br />
                  ソフトウェア開発のマネジメント経験、リーダー経験
                  <br />
                  複数チームによる大規模サービスの開発経験
                </td>
              </tr>
              <tr className={Style.recruitItemOverviewTable__item}>
                <th className={Style.recruitItemOverviewTable__head}>求める人物像</th>
                <td className={Style.recruitItemOverviewTable__content}>
                  積極的に行動し、自分を高めることができる方。
                  <br />
                  仕事に誠実で、楽しみながら進めることができる方。
                  <br />
                  報連相を意識し、周囲とコミュニケーションをとって仕事を遂行できる方。
                </td>
              </tr>
              <tr className={Style.recruitItemOverviewTable__item}>
                <th className={Style.recruitItemOverviewTable__head}>給与</th>
                <td className={Style.recruitItemOverviewTable__content}>
                  月収25万～
                  <br />
                  ※経験やスキルに応じて決定します
                  <br />
                  ※みなし残業45時間分含む。(超過分は別途残業代全額支給)
                  <br />
                  <br />
                  給与改定年2回
                  <br />
                  ※弊社の人事評価制度により給与改定を行います
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <EntryButton />

      <JobList />
    </Layout>
  );
};

export default Service;
